import React, { memo, Component } from 'react';
import cookies from 'browser-cookies';
import axios from 'axios';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';
import InfiniteScroll from 'react-infinite-scroll-component';

import Selected from '../components/selected';
import FieldText from '../components/field-text';
import ProfileModal from '../profile/components/modal/ProfileModal';
import ModalInformation from '../layout/components/modalInformation';
import Header from '../layout/components/header/header';
import CoachCard from '../components/coachCard';
import EmptyResult from '../layout/components/emptyResult';
import Step from '../layout/components/steps';
import '../App.css';
import './index.css';

const loader = <div className="loader">Loading...</div>;
const clientUrl = process.env.REACT_APP_CLIENT_URL || 'https://soar.com/';

const loadLabel = coachs => {
  if (coachs.totalCount === 0) {
    return '';
  }
  if (typeof coachs.totalCount === 'string') {
    return coachs.totalCount;
  }
  return coachs.totalCount === 1 ? '1 Coach found' : `${coachs.totalCount} Coaches found`;
};

const setCookie = (name, value, exp = 1) => {
  cookies.set(name, JSON.stringify(value), {
    expires: exp,
  });
};

const getCookie = name => {
  return cookies.get(name);
};

const defaultResult = {
  totalCount: 0,
  returnedCount: 0,
  items: [],
};

let cookie = '';

class SearchPage extends Component {
  state = {
    coachs: defaultResult,
    hasMore: true,
    isEmpty: false,
    isLoading: true,
    languagesAPI: [],
    pageNum: 1,
    languageCode: [],
    coachName: '',
    show: false,
    showInfo: false,
    profile: {},
    width: window.innerWidth,
    isLoadingProfile: true,
  };

  componentDidMount() {
    const cookieInfo = getCookie('coachOnlineInformation');
    if (!cookieInfo) this.setState({ showInfo: true });

    const fetchLanguage = async () => {
      let { data: value } = await axios.get(`https://api.soar.com/v1/Authority/icf/languages`);
      value = value.map(({ code, name }) => ({ value: code, label: name }));
      value.unshift({ value: null, label: 'No Preference' });
      this.setState({ languagesAPI: value });
      localStorage.setItem('coachOnlineLanguage', JSON.stringify(value));
    };

    const lastUpdateReq = getCookie('coachSearchLastReq');
    if (!lastUpdateReq) {
      localStorage.removeItem('coachOnlineLanguage');
      fetchLanguage();
      setCookie('coachSearchLastReq', 'true', 7);
    } else {
      cookie = localStorage.getItem('coachOnlineLanguage');
      if (cookie) {
        this.setState({ languagesAPI: JSON.parse(cookie) });
        cookie = '';
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener('resize', this.updateDimensions);
    this.fetchMyAPI();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  // eslint-disable-next-line react/sort-comp
  fetchMyAPI() {
    this.setState({
      hasMore: true,
      isEmpty: false,
      isLoading: true,
    });
    const { languageCode, coachName, coachs, pageNum } = this.state;

    const data = {
      searchDirectories: ['SokanuProBono'],
    };
    if (languageCode !== null && languageCode.length) {
      data.languages = [languageCode];
    }
    if (coachName) {
      data.coachName = coachName;
    }
    axios
      .post(
        `${process.env.REACT_APP_SOAR_API_HOST}/Search/coachDirectory?pageNum=${pageNum}&itemsPerPage=18`,
        data
      )
      .then(response => {
        const { data: responseData } = response;
        responseData.items = [...coachs.items, ...responseData.items];

        if (responseData.totalCount <= responseData.items.length) {
          this.setState({ hasMore: false });
          if (responseData.totalCount < 1) {
            this.setState({ isEmpty: true });
          }
        } else {
          this.setState({ pageNum: pageNum + 1 });
        }

        if (Object.keys(data).length === 0) {
          response.data.totalCount = 'Showing the last active Coaches.';
          if (pageNum === 1) {
            responseData.items.sort(() => 0.5 - Math.random());
          }
        }

        this.setState({ coachs: { ...coachs, ...responseData }, isLoading: false });
      });
  }

  async buttonAction() {
    this.setState({
      hasMore: false,
      coachs: defaultResult,
      isLoading: true,
      isEmpty: false,
      pageNum: 1,
    });
    const { languageCode, coachName } = this.state;

    const data = {
      searchDirectories: ['SokanuProBono'],
    };
    if (languageCode !== null && languageCode.length) {
      data.languages = [languageCode];
    }
    if (coachName) {
      data.coachName = coachName;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_SOAR_API_HOST}/Search/coachDirectory?pageNum=1&itemsPerPage=18`,
      data
    );

    if (response.data.totalCount <= 18) {
      this.setState({ hasMore: false });
      if (response.data.totalCount < 1) {
        this.setState({ isEmpty: true });
      }
    } else {
      this.setState({
        pageNum: 2,
        hasMore: true,
      });
    }
    if (Object.keys(data).length === 0) {
      response.data.totalCount = 'Showing the last active Coaches.';
    }

    this.setState({ coachs: response.data, isLoading: false });
  }

  // eslint-disable-next-line class-methods-use-this
  splitFunction(string, obj) {
    let tmp = '';
    let valueReturn = '';
    const split = string.split(',');
    for (let key = 0; key <= split.length; key++) {
      tmp = obj.find(options => options.value === split[key]);
      if (tmp) {
        valueReturn += `${tmp.label}, `;
      }
    }

    return valueReturn.slice(0, -2) || String(string).replace(/,/g, ', ');
  }

  handleLanguageChange(e) {
    const { value } = e;
    this.setState({ languageCode: value });
  }

  handleCoachNameChange(e) {
    const {
      target: { value },
    } = e;
    this.setState({ coachName: value });
  }

  handleCloseAlert(event) {
    event.preventDefault();
    cookies.set('coachOnlineInformation', 'true', {
      expires: 2,
    });
    this.setState({ showInfo: false });
  }

  handleShow = async coachId => {
    this.setState({ show: true, isLoadingProfile: true });
    const { data: user } = await axios.get(
      `${process.env.REACT_APP_SOAR_API_HOST}/user/${coachId}`
    );
    this.setState({ profile: user, isLoadingProfile: false });
  };

  handleClose(event) {
    event.preventDefault();
    this.setState({ show: false });
  }

  render() {
    const {
      coachs,
      hasMore,
      languagesAPI,
      isLoading,
      show,
      showInfo,
      profile,
      isLoadingProfile,
      width,
      languageCode,
      coachName,
      isEmpty,
    } = this.state;
    const languageValue = languagesAPI.find(language => language.value === languageCode);
    const imgLogo =
      width < 500 ? '/images/powered-by-soar-mobile.png' : '/images/powered-by-soar.png';

    return (
      <InfiniteScroll
        style={{ overflow: 'unset' }}
        dataLength={coachs.items.length}
        next={() => this.fetchMyAPI()}
        hasMore={hasMore}
        scrollableTarget="#soar-cards"
        endMessage={<></>}
      >
        <Header />
        <section>
          <div className="app-content pure-g">
            <div className="pure-1 divFlex divStep">
              <Step
                color="rose"
                title="Step 1"
                subTitle="Search Coaches"
                text="Use the filters below and click search to see your results."
                margin
              />
              <Step
                color="orange2"
                title="Step 2"
                subTitle="Review Coaches"
                text="Review coach videos, bios, and more to find the best match."
                margin
              />
              <Step
                color="teal"
                title="Step 3"
                subTitle="Schedule a Meeting"
                text="*Remember to use your Promo Code when scheduling."
              />
            </div>
            <div className="pure-g">
              <h2 className="section_title w-100">
                Find Your Coach
                <a href={clientUrl}>
                  <img src={imgLogo} className="image_powered" alt="SOAR" />
                </a>
              </h2>
            </div>
            <div className="pure-g divFlex divSearch">
              <Selected
                id="id"
                label="Language:"
                className="selected flex-item"
                options={languagesAPI}
                value={languageValue}
                onChange={e => this.handleLanguageChange(e)}
                img="/images/language.svg"
              />
              <FieldText
                id="id"
                label="Search by Name:"
                placeholder="Coach Name"
                className="selected field-text flex-item"
                value={coachName}
                onChange={e => this.handleCoachNameChange(e)}
              />
              <a
                className="item button button-medium button-blue button-rounded coachSearch_button"
                onClick={() => this.buttonAction()}
              >
                Search
              </a>
            </div>
          </div>
        </section>
        <section id="soar-cards">
          <div className="app-content pb-80">
            <div className="pure-g">
              <h2 className="section_title recently">{loadLabel(coachs)}</h2>
            </div>
            <div className="pure-g coach-cards">
              <div className="pure-u-1">
                {coachs.items.map(item => (
                  <CoachCard
                    key={item.id}
                    img={
                      item.profilePictUrl && item.profilePictUrl !== ''
                        ? item.profilePictUrl.replace('http://', 'https://')
                        : `https://i1.wp.com/cdn.auth0.com/avatars/${
                            item.name ? item.name.substring(0, 1).toLowerCase() : 's'
                          }.png`
                    }
                    alt={item.vanityUrl}
                    name={item.name}
                    location={
                      `${item.locality ? item.locality : ''}` +
                      `${item.region ? `, ${item.region}` : ''}`
                    }
                    language={this.splitFunction(item.icfLanguages, languagesAPI)}
                    handleShow={() => this.handleShow(item.id)}
                  />
                ))}
                {isEmpty && <EmptyResult />}
              </div>
            </div>
          </div>
        </section>
        {isLoading && loader}
        <ModalInformation show={showInfo} handleClose={e => this.handleCloseAlert(e)} />
        <ProfileModal
          show={show}
          profile={profile}
          isLoadingProfile={isLoadingProfile}
          splitFunction={this.splitFunction}
          languagesAPI={languagesAPI}
          handleClose={e => this.handleClose(e)}
        />
      </InfiniteScroll>
    );
  }
}

export default memo(SearchPage);
