import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import ReactModal from 'react-modal';

import Avatar from '../../../components/avatar';
import { URL_ICF_CERT } from '../../constants/profile-constants';
import { verifyURL } from '../../../shared/helpers/converter-helper';
import './style.scss';

const loader = <div className="loader">Loading...</div>;

const ProfileModal = props => {
  const { show, handleClose, profile, isLoadingProfile, splitFunction, languagesAPI } = props;
  const getBadgeUrl = () => {
    let url = '';
    if (profile.customFields.icfCertLevel) {
      url = URL_ICF_CERT[profile.customFields.icfCertLevel];
    }
    return url;
  };
  const buildLocation = () => {
    if (profile.addresses[0]) {
      const addresses = profile.addresses[0];
      return [addresses.locality, addresses.region, addresses.countryCode]
        .filter(Boolean)
        .join(', ');
    }
    return '';
  };
  const buildProfileImg = item => {
    let img = `https://i1.wp.com/cdn.auth0.com/avatars/${item.givenName
      .substring(0, 1)
      .toLowerCase()}.png`;

    if (item.customFields.ProfilePictUrl && item.customFields.ProfilePictUrl !== '') {
      img = item.customFields.ProfilePictUrl.replace('http://', 'https://');
    }
    return img;
  };
  const customStyles = {
    content: {
      zIndex: 2,
      border: 'none',
      background: 'none',
      padding: '0',
      top: '0',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'center',
      bottom: 'auto',
      outline: 'none',
    },
  };

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset';
  }, [show]);

  return (
    <>
      <ReactModal
        isOpen={show}
        contentLabel="Minimal Modal Example"
        style={customStyles}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick
        className="pure-u-xl-3-5 pure-u-lg-4-5 pure-u-md-7-8 pure-u-sm-5-5"
      >
        <div className="profile-modal-container pure-u-xl-5-5 pure-u-lg-5-5pure-u-md-5-5 pure-u-sm-5-5">
          {isLoadingProfile ? (
            loader
          ) : (
            <>
              <div className="profile-modal-header">
                <div className="info pure-u-xl-3-5 pure-u-lg-3-5 pure-u-md-3-5 pure-u-sm-5-5 pure-u-5-5">
                  <Avatar
                    img={buildProfileImg(profile)}
                    alt={`${profile.givenName} ${profile.familyName}`}
                  />
                  <ul className="couch-info">
                    <li>
                      {profile.givenName} {profile.familyName}
                    </li>
                    <li>
                      <i className="profile-icon icon-location-small">&#xe803;</i>
                      <span>{buildLocation()}</span>
                    </li>
                    {profile.customFields.icfLanguages && (
                      <li>
                        <i className="profile-icon icon-language">&#xe801;</i>
                        <span>
                          {splitFunction(profile.customFields.icfLanguages, languagesAPI)}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="modal-profil-actons pure-u-xl-2-5 pure-u-lg-2-5 pure-u-md-2-5 pure-u-sm-5-5 pure-u-5-5">
                  {profile.customFields.icfDigitalBadgeUrl && (
                    <a
                      href={profile.customFields.icfDigitalBadgeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="header-badge"
                    >
                      <img src={getBadgeUrl()} alt={profile.icfCertLevel} />
                    </a>
                  )}
                  {profile.customFields.SchedulingIds && (
                    <a
                      className="pure-button blue-button action-button"
                      target="_blank"
                      href={`https://app.soar.com/${profile.customFields.VanityUrl}?isSchedule=true`}
                    >
                      <i className="profile-icon icon-calendar">&#xe804;</i>
                      &nbsp; Schedule Now
                    </a>
                  )}
                  <a className="silver-gray-button action-button" href="#" onClick={handleClose}>
                    Close
                  </a>
                </div>
              </div>
              <div className="profile-modal-body">
                <div className="modal-body-left pure-u-lg-1-5 pure-u-md-2-6 pure-u-sm-1-5 sm-hide">
                  <ul className="modal-profile-menu">
                    {profile.customFields.IntroVideoUrl && (
                      <li>
                        <a href="#section_video">
                          <i className="profile-icon icon-video-small">&#xe807;</i>
                          &nbsp;Video
                        </a>
                      </li>
                    )}
                    {profile.customFields.AboutMe && (
                      <li>
                        <a href="#section-about">
                          <i className="profile-icon icon-about-small">&#xe806;</i>
                          &nbsp;About
                        </a>
                      </li>
                    )}
                    {profile.customFields.ShortBio && (
                      <li>
                        <a href="#section-bio">
                          <i className="profile-icon icon-bio-small">&#xe805;</i>
                          &nbsp;Bio
                        </a>
                      </li>
                    )}
                    {profile.customFields.icfDigitalBadgeUrl && (
                      <li className="icf_badge">
                        <a
                          href={profile.customFields.icfDigitalBadgeUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={getBadgeUrl()} alt={profile.icfCertLevel} width="92px" />
                        </a>
                      </li>
                    )}
                    <li className="modal_soar_logo">
                      <img src="/images/logo.png" alt="Soar" />
                    </li>
                  </ul>
                </div>
                <div className="modal-body-right pure-u-lg-4-5 pure-u-md-4-6 pure-u-sm-4-5">
                  {profile.customFields.IntroVideoUrl && (
                    <div className="section-video" id="section_video">
                      <iframe
                        src={verifyURL(profile.customFields.IntroVideoUrl)}
                        frameBorder="0"
                        allowFullScreen
                        title={`${profile.givenName}'s intro video`}
                        className="embed-responsive-item"
                      />
                    </div>
                  )}
                  {profile.customFields.AboutMe && (
                    <div className="section" id="section-about">
                      <p className="title">About</p>
                      <p className="section-content">{Parser(profile.customFields.AboutMe)}</p>
                    </div>
                  )}
                  {profile.customFields.ShortBio && (
                    <div className="section" id="section-bio">
                      <p className="title">Short Bio</p>
                      <p className="section-content">{Parser(profile.customFields.ShortBio)}</p>
                    </div>
                  )}
                  <div className="section-logo">
                    <span>POWERED BY</span>
                    <img src="/images/logo.png" alt="Soar" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </>
  );
};

ProfileModal.propTypes = {
  profile: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  splitFunction: PropTypes.func.isRequired,
  languagesAPI: PropTypes.array.isRequired,
};

export default ProfileModal;
