import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../avatar';
import '../../App.css';
import './index.css';

const CardCoach = props => {
  const { name, location, language, img, alt, handleShow } = props;
  return (
    <>
      <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 coachCard-block">
        <div className="coachCard">
          <div className="coachCard-content">
            <div className="pure-g">
              <Avatar img={img} alt={alt} />
              <div>
                <div className="pure-g">
                  <div className="coachCard__fullname">{name}</div>
                </div>
                {location && (
                  <div className="pure-g">
                    <div className="coachCard-icon">
                      <img src="/images/location-small.svg" alt="" />
                    </div>
                    <div className="coachCard__description">{location}</div>
                  </div>
                )}
                {language && (
                  <div className="pure-g">
                    <div className="coachCard-icon">
                      <img src="/images/language-small.svg" alt="" />
                    </div>
                    <div className="coachCard__description">{language}</div>
                  </div>
                )}
              </div>
            </div>
            <a className="coachCard__button" onClick={handleShow}>
              View Profile
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

CardCoach.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string,
  language: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  handleShow: PropTypes.string,
};

CardCoach.defaultProps = {
  location: '',
  language: '',
  img: '',
  alt: '',
  handleShow: () => {},
};

export default CardCoach;
