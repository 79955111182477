import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const HelpModal = props => {
  const { show, handleClose } = props;

  const customStyles = {
    content: {
      zIndex: 2,
      border: 'none',
      background: 'none',
      top: '0',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'center',
      bottom: 'auto',
      outline: 'none',
      padding: '30px',
      height: 'fit-content',
      alignSelf: 'center',
    },
  };

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset';
  }, [show]);

  return (
    <>
      <ReactModal
        isOpen={show}
        contentLabel="Minimal Modal Example"
        style={customStyles}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick
        className="pure-u-xl-3-5 pure-u-lg-4-5 pure-u-md-7-8 pure-u-sm-5-5 help-container"
      >
        <div className="help-modal-container pure-u-xl-5-5 pure-u-lg-5-5pure-u-md-5-5 pure-u-sm-5-5">
          <div className="help-modal">
            <div className="help-modal-header">
              <p className="title">Need Help?</p>
            </div>

            <div className="content">
              <p>
                Any questions, issues or concerns about your membership should be sent to
                CareerExplorer via email at:{' '}
                <a href="mailto:contact@sokanu.com">contact@sokanu.com</a>
              </p>

              <p>
                Any questions, issues or concerns about the technology, powered through the Soar
                Coaching Platform, or a specific coach, should be directed to Soar via email at:{' '}
                <a href="mailto:support@soar.com">support@soar.com</a>
              </p>
            </div>
            <div className="help-modal-footer">
              <a className="close-help" href="#" onClick={handleClose}>
                Close
              </a>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

HelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default HelpModal;
