import React, { memo } from 'react';
import classNames from 'classnames';
import './index.css';

const Step = props => {
  const { color, title, subTitle, text, margin } = props;
  const box = classNames('step', {
    step__green: color === 'green',
    step__orange: color === 'orange',
    step__purple: color === 'purple',
    step__rose: color === 'rose',
    step__orange2: color === 'orange2',
    step__teal: color === 'teal',
    step__margin: margin === true,
  });
  return (
    <div className={box}>
      <div className="step__content">
        <strong className="step__title">{title}</strong>
        <p>
          <strong className="step__subTitle">{subTitle}</strong>
        </p>
        <span className="step__text">{text}</span>
      </div>
    </div>
  );
};

export default Step;
