import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import './index.css';

const ModalInformation = props => {
  const { show, handleClose } = props;

  const customStyles = {
    content: {
      zIndex: 2,
      border: 'none',
      background: 'none',
      overflow: 'visible',
      padding: '0',
      left: '0px',
      right: '0px',
      justifyContent: 'center',
      bottom: 'auto',
      display: 'flex',
    },
  };

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset';
  }, [show]);

  return (
    <ReactModal isOpen={show} contentLabel="Minimal Modal Example" style={customStyles}>
      <div className="information-modal-container pure-u-xl-3-5 pure-u-lg-4-5 pure-u-md-4-5 pure-u-sm-5-5">
        <div className="modalInformation">
          <div className="modalInformation__header">
            <strong>Important Notice:</strong>
          </div>
          <div className="modalInformation__content">
            <div className="modalInformation__content__holder">
              <div className="modalInformation__content__holder__text">
                <p>
                  Career coaching… and coaching in general… involves partnering with clients in a
                  thought-provoking and creative process that inspires them to maximize their personal
                  and professional potential. The process of coaching often unlocks previously untapped
                  sources of imagination, productivity and leadership.
                </p>
                <p>
                  Coaching differs from other support modalities, including consulting, training,
                  mentoring, counseling and psychotherapy.
                </p>
                <p>
                  If one or more of the following statements applies to you, coaching may not be the
                  best fit for your personal and professional growth at this time. Instead, you may wish
                  to seek the support of a licensed counselor or psychotherapist:
                </p>
                <p>
                  • I have experienced excessive feelings of worry, nervousness or apprehension about
                  real-life events, or recurring intrusive thoughts or concerns on more days than not
                  for at least six months.
                </p>
                <p>
                  • Feelings of severe sadness, emptiness, and hopelessness have disrupted my daily life
                  and persisted for more than two weeks.
                </p>
                <p>
                  • I am experiencing recurrent episodes of or persistent behaviors related to an eating
                  disorder.
                </p>
                <p>
                  • I have experienced a terrifying event (e.g., natural disaster, car accident) and am
                  currently experiencing feelings of trauma or stress that have lasted at least one
                  month.
                </p>
                <p>
                  If you are experiencing recurrent thoughts of death, suicidal ideation, suicidal
                  plans, harming yourself or harming others, please call your local emergency number
                  immediately or contact a crisis or suicide prevention hotline.
                </p>
                <p>Contact information for crisis centers:</p>
                <p>
                  Befrienders Worldwide:{' '}
                  <a href="https://www.befrienders.org">https://www.befrienders.org</a>
                </p>
                <p>
                  International Association for Suicide Prevention:{' '}
                  <a href="https://www.iasp.info/resources/Crisis_Centres">
                    https://www.iasp.info/resources/Crisis_Centres
                  </a>
                </p>
                <p>
                  International Suicide Prevention Wiki:{' '}
                  <a href="http://suicideprevention.wikia.com/wiki/International_Suicide_Prevention_Directory">
                    http://suicideprevention.wikia.com/wiki/International_Suicide_Prevention_Directory
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modalInformation__footer">
          <span onClick={handleClose}>I have read and understand</span>
        </div>
      </div>
    </ReactModal>
  );
};
export default ModalInformation;
