import React, { useState } from 'react';
import HelpModal from './helpModal';
import './header.scss';

const Header = () => {
  const [helpShow, setHelpShow] = useState(false);

  const handleCloseHelp = event => {
    event.preventDefault();
    setHelpShow(false);
  };

  return (
    <>
      <header className="header-default">
        <div className="header-default__logo">
          <a href="/">
            <img width="120" src="/images/sokanu-logo-xs.png" alt="Soar" className="brand-image" />
          </a>
          <a
            className="coach-header-button coach-header-transparent coach-header-right coach-header-top"
            onClick={() => setHelpShow(true)}
          >
            Help
          </a>
          <div className="header-default__rectangle" />
        </div>
        <div className="pure-u-md-1-2">
          <p className="header-default__title">Experience Career Coaching</p>
          <div className="header-default__subtitle">
            The key to making informed decisions about your career development and trajectory.
          </div>
          <div className="header-default__content">
            Find the right coach and use your promo code to schedule a free discovery session.
          </div>
        </div>
        <div className="pure-u-md-1-2 header-default__image_section">
          <img src="/images/key-to-success.png" className="header-default__image" alt="meeting" />
        </div>
      </header>

      <HelpModal show={helpShow} handleClose={e => handleCloseHelp(e)} />
    </>
  );
};

export default Header;
